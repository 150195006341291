
@font-face {
	font-family: kalameh;
	font-style: normal;
	font-weight: 700;
	src: url('./fonts/kalameh/Kalameh-Light.6e146d5.woff') format('woff') ;	 
  
}


@font-face {
	font-family: BYekan;
	font-style: normal;
	font-weight: 700;
	src: url('./fonts/yekan3/woff/IRANSansX-Bold.woff') format('woff') ;	 
  
}

@font-face {
	font-family: MorabbaHeavy;
 	src: url('./fonts/Morabba-Heavy.woff') format('woff') ;	 
}



@font-face {
	font-family: MorabbaRegular;
 	src: url('./fonts/Morabba-Regular.woff') format('woff') ;	 
  
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
 
}
 