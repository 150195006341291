#userPic {
  width: 38px;
}

#customFooter {
  background-color: #fffffff0;
  border-radius: 24px;
  position: absolute;
  height: 150px;
  overflow-y: hidden;
  z-index: 10000;
  bottom: 36px;
  /*width: 100%;*/
  margin-right: 20px;
  margin-left: 20px;
  width: calc(100% - 40px);
  padding: 10px;
  bottom: 20px;
}

#customFooterExpand {
  background-color: #fffffff0;
  border-radius: 24px;
  position: absolute;
  /*height: 100%;*/
  top: 30px;
  overflow-y: auto;
  z-index: 10000;
  bottom: 36px;
  /*width: 100%;*/
  margin-right: 20px;
  margin-left: 20px;
  width: calc(100% - 40px);
  padding: 10px;
  bottom: 20px;
}

#colMainFooter {
  overflow-y: auto;
  height: calc(100% - 40px);
  /*100px;*/
}

#customHeader {
  background-color: #fffffff0;
  border-radius: 24px;
  position: absolute;
  /* height: 200px; */
  z-index: 10000;
  top: 20px;
  /*width: 100%;*/
  margin-right: 20px;
  margin-left: 20px;
  width: calc(100% - 40px);
  padding: 10px;

}

#buttonTop {
  /*margin-bottom: -40px;
  position: absolute;*/
  height: 100px;
  margin-top: -20px;
  z-index: -1;
}

#colButtonTop {
  height: 20px;
}

#buttonFooter {
  /*margin-top: -40px;*/
  height: 100px;
  margin-top: -60px;
  z-index: -1;
  position: fixed;
}




.itemFilter {
  background: #D9D9D94A;
  border-radius: 18px;
  Border: 1px;
  border: 1px solid #00000008;
  cursor: pointer;
}

.fn-kalameh {
  font-family: kalameh;
}

.fs1 {
  font-family: MorabbaRegular;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
}

.fs2 {
  font-family: MorabbaRegular;
  font-size: 9px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;

}

.fs3 {
  color: #7B7B7B;
  font-family: MorabbaRegular;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: right;

}

.fs4 {
  font-family: MorabbaRegular;
  font-size: 25px;
  font-style: italic;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;

}

.fs5 {
  font-family: BYekan;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
}

.fs6 {
  font-family: BYekan;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: justified;
}

.fs7 {
  font-family: BYekan;
  font-size: 21px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;

}

.fs8 {
  font-family: BYekan;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: right;

}

.fs9 {
  font-family: BYekan;
  font-size: 19px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
}

.fs13 {
  font-size: 13px;
}

.fs11 {
  font-size: 11px;
}

.fs18 {
  font-family: Kalameh;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: justified;

}

.fs20 {
  font-family: Kalameh;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: justified;

}

.color1 {
  color: #676767;
}


.customCurrentLocation {
  bottom: 200px !important;
}

.myRounded {
  border-radius: 24px !important;
}

.tabPanel {
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.btn {
  background: #F8F8F8;
  font-family: kalameh;
}

.btn-active {
  background: #4869E0;
  color: #FFFFFF;
}

.horzintalLine {
  border: 1px solid #00000024;
}

.customBusget1 {
  color: #00A96C;

  border-radius: 25px;
  background: #EDF7ED;


}

.picComment {
  width: 90px;
  border-radius: 45px;
}

.picFooter {
  width: 44px;
  border-radius: 12px;
}

.likeCaption {
  color: #00A96C;
  font-size: 12px;
}

@media (max-width: 575px) {
  .my-d-sm-block {
    display: block !important;
  }
}
@media (max-width: 575px) {
  .myFlex-d-sm-block {
    display: flex !important;
  }
  .fnBreak{
    width: 130px;
  }
}
.rating{
width:15px
}